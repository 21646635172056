<template>
    <div class="inner_pages" id="progress_index">
        <div class="respective_content">
                <div class="acton_header">
                    <div class="left_section">
                        <h2>Client Tracking </h2>
                    </div>
                    <div class="right_section"></div>
                </div>
            <div class="result_wpr new" v-if="loader">
                <div class="action_content has-loader">
                    <quote-loader class="mt-4" />
                </div>
            </div>
            <div class="result_wpr new" v-else>
                <div class="action_content mb-5">
                    <progress-report v-model="showProgress" :progress-tracking="progressTracking" :contact-relation="contactRelation" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { mapState, mapActions } from 'vuex'

const ProgressReport = defineAsyncComponent(() => import('@/pages/progress-tracking/components/ProgressReport'))

export default {
    name: 'form-response',

    data() {
        return {
            tutorial: false,
            loader: true,
            progressTracking: {},
            contactRelation: {},
            showProgress: false,
        };
    },

    components: {
        ProgressReport,
    },

    computed: mapState({
        user: state => state.authModule.user,
    }),

    mounted() {
        const vm = this;

        const params = {
            contact: vm.$route.params.contact,
            tracking: vm.$route.params.progress,
        };

        vm.handleTrackingResponse(params);
    },

    methods: {
        ...mapActions({
            getProgressContactRelation: 'progressTrackingModule/getProgressContactRelation'
        }),

        handleTrackingResponse(params) {
            const vm = this;

            vm.loader = true;

            vm.getProgressContactRelation(params).then((result) => {
                if (result) {
                    vm.progressTracking = result.progress;
                    vm.contactRelation  = result.relation;
                    vm.showProgress     = true;
                }

                vm.loader = false;
            });
        },
    }
}
</script>

<style scoped>
.result_wpr.new {
    min-height: 65vh;
}

.result_wpr.new .quote_wpr {
    height: 100%;
    justify-content: center;
}

.action_content {
    min-height: 100%;
}

.action_content.has-loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

:deep(.action_content .report_section) {
    padding: 0;
}
</style>