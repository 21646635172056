<template>
    <div class="inner_pages">
        <div class="respective_content" id="progress_tracking_index">
            <div class="acton_header">
                <div class="left_section">
                    <h2>Client Tracking </h2>
                    <div class="search_area">
                        <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="search" />
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                </div>
                <div class="right_section">
                    <ul>
                        <li @click="sortProgressTracking = !sortProgressTracking" v-tooltip="`Sort Progress Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                        <li v-if="companyUserCan('create', 'progress-tracking')" @click="importProgressTracking = !importProgressTracking" v-tooltip="`Import a shared client tracking`" position="bottom"><img src="@/assets/images/import.svg"></li>
                        <li class="add_btn light pointer" @click="newProgressTracking = true;"><i class="fas fa-plus-circle"></i>Create Client Tracking</li>
                    </ul>
                </div>
            </div>
            <div class="result_wpr new">
                <div class="actions">
                    <ul>
                        <li class="p-0" v-show="selectedProgressTracking.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedProgressTracking.length ? `${selectedProgressTracking.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkActionDropdown = !bulkActionDropdown" v-click-outside="closeBulkActionDropdown" v-if="selectedProgressTracking.length">
                            Bulk Action<i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkActionDropdown ? 'active' : ''">
                                <ul>
                                    <li v-if="companyUserCan('update', 'progress-tracking')" @click="handleAssignContacts(selectedProgressTracking)">Assign Contacts</li>
                                    <li v-if="companyUserCan('update', 'progress-tracking')" @click="handleUnassignContacts(selectedProgressTracking)">Unassign Contacts</li>
                                    <li v-if="companyUserCan('delete', 'progress-tracking')" @click="handleDeleteProgressTracking(selectedProgressTracking);" class="danger">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs tutorial_btn" @click="tutorialList = !tutorialList;" v-click-outside="closeTutorialListDropdown">
                            <label>Tutorials</label><img src="@/assets/images/play.svg">
                            <div class="dropdown_wpr" :class="tutorialList ? 'active' : ''">
                                <ul>
                                    <li @click="tutorial = true;">How to use client tracking</li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <ul class="right">
                        <li class="optionDrops contacts-tabs" @click="actionList = !actionList"  v-click-outside="closeSortingDropdown">
                            {{ filterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="actionList ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                        {{ filter.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops sort_list" @click="togglePerPageFilter()" v-click-outside="closePerPageDropdown">
                            Show {{ params.per_page }} <i  class="fas fa-angle-down"></i>
                            <page-filter v-model="perPageFilter" :type="1" :is-dropdown="true" ref="progress-tracking-per-page-filter" />
                        </li>
                        <li class="filter_btn" @click="mobile_filter = true;">
                            <span></span>
                            <span></span>
                            <span></span>
                        </li>
                    </ul>
                </div>
                <div class="listing_loader" v-if="progressTrackingLoader"><quote-loader /></div>
                <div class="action_content" v-if="!progressTrackingLoader">
                    <ul class="playbook_list">
                        <li v-for="(progressTracking, p) in progressTrackings.data" :key="p">
                            <div class="plybook_item" @mouseenter="showAction(true)" @mouseleave="showAction(false)">
                                <div class="item_img" :class="{ active : selectedProgressTracking.includes(progressTracking.id) }">
                                    <img v-if="progressTracking.dashboard_thumb" :src="progressTracking.dashboard_thumb" class="img">
                                    <img v-else src="@/assets/images/thumb/default-progress.svg" class="img">
                                    <span v-if="companyUserCan('update', 'progress-tracking')" class="replace_img" @click="editThumbnail = true; activeProgressTracking = progressTracking;">
                                        <img src="@/assets/images/image.svg">
                                    </span>
                                    <ul class="statistics">
                                        <li>
                                            <label :for="`progress-tracking-${progressTracking.id}`" class="checkbox">
                                                <input type="checkbox" :id="`progress-tracking-${progressTracking.id}`" :value="progressTracking.id" v-model="selectedProgressTracking" hidden>
                                                <span><i class="fas fa-check"></i></span>
                                            </label>
                                        </li>
                                        <li>{{ progressTracking.total_assigned ? progressTracking.total_assigned : 0 }} Assigned</li>
                                        <li>{{ progressTracking.total_submitted ? progressTracking.total_submitted : 0 }} Submitted</li>
                                        <li>{{ progressTracking.recurring_type_text ? progressTracking.recurring_type_text : '' }}</li>
                                    </ul>
                                </div>
                                <div v-tooltip="progressTracking.name">
                                    <a class="item_ttl pointer" v-if="companyUserCan('update', 'progress-tracking')" @click="toggleProgressTrackingWizard(progressTracking)">{{ progressTracking.name }}</a>
                                    <a class="item_ttl pointer" v-else>{{ progressTracking.name }}</a>
                                </div>
                                <div class="item_info">
                                    <div class="member pointer" @click="manageContact = true; activeProgressTracking = progressTracking;" v-if="!progressTracking.is_shared_with_contact" v-tooltip="`Clients Assigned to this Client Tracking`">
                                        <img src="@/assets/images/member.svg">
                                        {{ progressTracking.total_assigned ? progressTracking.total_assigned : 0 }} Assigned
                                    </div>
                                    <ul>
                                        <li @click="progressTrackingLink = true; activeProgressTracking = progressTracking;"><span v-tooltip="`Live Client Tracking Link`"><i class="fas fa-link"></i></span></li>
                                        <li @click="moreOption(progressTracking.id)">
                                            <span v-tooltip="`Additional Actions`"><i class="fas fa-ellipsis-v"></i></span>
                                            <ul class="row_option mr-4 py-1" :class="{'active' : moreOptionDropdown ==  progressTracking.id}">
                                                <li @click="toggleProgressTrackingWizard(progressTracking)" v-if="companyUserCan('update', 'progress-tracking')">Edit</li>
                                                <li @click="editThumbnail = true; activeProgressTracking = progressTracking;" v-if="companyUserCan('update', 'progress-tracking')">Edit Thumbnail Image</li>
                                                <li @click="renameProgressTracking = true; activeProgressTracking = progressTracking;" v-if="companyUserCan('update', 'progress-tracking')">Rename</li>
                                                <li @click="manageContact = true; activeProgressTracking = progressTracking;" v-if="!progressTracking.is_shared_with_contact">Manage</li>
                                                <li @click="previewProgressTracking = true; activeProgressTracking = progressTracking;">Preview</li>
                                                <li @click="handleShareProgressTracking(progressTracking)" >Share</li>
                                                <li @click="handleAssignContacts([progressTracking.id])" v-if="!progressTracking.is_shared_with_contact">Assign Contacts</li>
                                                <li @click="handleDuplicateProgressTracking([progressTracking.id]);" v-if="companyUserCan('create', 'progress-tracking')">Duplicate</li>
                                                <li @click="handleDeleteProgressTracking([progressTracking.id]);" v-if="companyUserCan('delete', 'progress-tracking')" class="danger" >Delete</li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="empty_box" v-if="!progressTrackings.total">
                        <img src="@/assets/images/empty_state.svg">
                        <h4>Looks like you don't have any client tracking yet. Click the play button to get started.</h4>
                    </div>
                </div>
                <div class="list_footer">
                    <ul>
                        <li>{{ progressTrackings.from ? progressTrackings.from : 0 }} - {{ progressTrackings.to ? progressTrackings.to : 0 }} of {{ progressTrackings.total ? progressTrackings.total : 0 }}</li>
                    </ul>
                </div>
                <div class="pagination" v-show="progressTrackings.total">
                    <pagination v-model="page" :pages="progressTrackings.last_page" :range-size="0" @update:modelValue="handlePagination" />
                </div>
            </div>
            <div class="header_filter" :class="{'show' : mobile_filter}">
                <button class="close_btn" @click="mobile_filter = false;"><i class="fas fa-times"></i></button>
                <div class="top_area">
                    <h3>Actions</h3>
                    <ul>
                        <li @click="sortProgressTracking = !sortProgressTracking" v-tooltip="`Sort Client Dashboard`" position="bottom"><img src="@/assets/images/slider3.svg"></li>
                        <li v-if="companyUserCan('create', 'progress-tracking')" @click="importProgressTracking = !importProgressTracking" v-tooltip="`Import a shared client tracking`" position="bottom"><img src="@/assets/images/import.svg"></li>
                    </ul>
                </div>
                <div class="action_area">
                    <h3 class="sub_heading2">Filters</h3>
                    <div class="search_area">
                        <input type="text" placeholder="Search" ref="search" @input="isTyping = true" v-model.trim="search" />
                        <button class="search_btn">
                            <i class="fas fa-search"></i>
                        </button>
                    </div>
                    <ul>
                        <li v-show="selectedProgressTracking.length || showContent">
                            <label for="check" class="checkbox">
                                <h5 class="p-0">{{ selectedProgressTracking.length ? `${selectedProgressTracking.length} Selected` : `Select thumbnails for bulk action`}}</h5>
                            </label>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="bulkActionDropdown2 = !bulkActionDropdown2" v-click-outside="closeBulkActionDropdown2" v-if="selectedProgressTracking.length">
                            Bulk Action<i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="bulkActionDropdown2 ? 'active' : ''">
                                <ul>
                                    <li v-if="companyUserCan('update', 'progress-tracking')" @click="handleAssignContacts(selectedProgressTracking)">Assign Contacts</li>
                                    <li v-if="companyUserCan('update', 'progress-tracking')" @click="handleUnassignContacts(selectedProgressTracking)">Unassign Contacts</li>
                                    <li v-if="companyUserCan('delete', 'progress-tracking')" @click="handleDeleteProgressTracking(selectedProgressTracking);" class="danger">Delete</li>
                                </ul>
                            </div>
                        </li>
                        <li class="optionDrops contacts-tabs" @click="actionList2 = !actionList2"  v-click-outside="closeSortingDropdown2">
                            {{ filterTitle }} <i class="fas fa-angle-down"></i>
                            <div class="dropdown_wpr" :class="actionList2 ? 'active' : ''">
                                <ul>
                                    <li v-for="(filter, f) in filters" :key="f" :class="{'active' : filter.title == filterTitle}" @click="sortingFilter(filter)">
                                        {{ filter.title }}
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="modal secondary video_player" v-if="tutorial">
            <div class="modal_container">
                <div class="modal_header">
                    <button class="close_btn" @click="tutorial = false"><i class="fas fa-times"></i></button>
                </div>
                <div class="video_wpr">
                    <iframe src="https://www.loom.com/embed/f44f70f791e4494682c3b1480f5c1b8f?hide_owner=true&hide_share=true&hide_title=true&hideEmbedTopBar=true" class="loom-wrapper" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </div>
                <div class="modal_footer">
                    <a href="https://help.thrivecoach.io/" target="_blank" class="more_tutorial">More Tutorials <i class="fas fa-long-arrow-alt-right"></i></a>
                </div>
            </div>
        </div>
        <edit-thumbnail-asset v-model="editThumbnail" title="Client Tracking" module="progress-tracking" :selected-asset="activeProgressTracking" :refresh-asset="refreshProgressTracking" />
        <import-share-link v-model="importProgressTracking" title="Client Tracking" module="Progress Tracking" :refresh-asset="refreshProgressTracking" :has-saved-styling="true" />
        <sort-asset v-model="sortProgressTracking" title="Client Tracking" asset-type="progress-tracking" :update-sorting="sortingFilter" />
        <assign-contact v-model="assignContact" :progress-tracking-ids="progressTrackingIds" />
        <unassign-contact v-model="unassignContact" :progress-tracking-ids="progressTrackingIds" />
        <progress-tracking-wizard v-model="progressTrackingWizard" />
        <rename-progress-tracking v-model="renameProgressTracking" :progress-tracking="activeProgressTracking"/>
        <share-component v-model="shareProgressTracking" title="Client Tracking" module="progress-tracking" :selected-asset="activeProgressTracking" />
        <asset-link v-model="progressTrackingLink" title="Client Tracking" module="progress-tracking" :selected-asset="activeProgressTracking" />
        <manage-contact v-model="manageContact" :progress-tracking="activeProgressTracking" />
        <preview-progress-tracking v-model="previewProgressTracking" :progress-tracking="activeProgressTracking" />
        <new-progress v-model="newProgressTracking" :refresh-asset="refreshProgressTracking" />
        <progress-tables v-model="progressTable" />
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const InnerSidebar = defineAsyncComponent(() => import('@/components/InnerSidebar'))
    const EditThumbnailAsset = defineAsyncComponent(() => import('@/components/EditThumbnailAsset'))
    const ImportShareLink = defineAsyncComponent(() => import('@/components/ImportShareLink'))
    const SortAsset = defineAsyncComponent(() => import('@/components/SortAsset'))
    const AssignContact = defineAsyncComponent(() => import('@/pages/progress-tracking/components/AssignContact'))
    const UnassignContact = defineAsyncComponent(() => import('@/pages/progress-tracking/components/UnassignContact'))
    const ProgressTrackingWizard = defineAsyncComponent(() => import('@/pages/progress-tracking/components/Wizard'))
    const RenameProgressTracking = defineAsyncComponent(() => import('@/pages/progress-tracking/components/RenameProgressTracking'))
    const ShareComponent = defineAsyncComponent(() => import('@/components/ShareComponent'))
    const AssetLink = defineAsyncComponent(() => import('@/components/AssetLink'))
    const ManageContact = defineAsyncComponent(() => import('@/pages/progress-tracking/components/ManageContact'))
    const PreviewProgressTracking = defineAsyncComponent(() => import('@/pages/progress-tracking/components/PreviewProgressTracking'))
    const NewProgress = defineAsyncComponent(() => import('@/pages/progress-tracking/components/NewProgress'))
    const ProgressTables = defineAsyncComponent(() => import('@/pages/progress-tracking/components/ProgressTables'))

    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'
    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Progress Tracking',

        data () {
            return {
                tutorialList: false,
                tutorial: false,
                filterTitle: 'Custom Arrangement',
                filters: [
                    { title: 'Custom Arrangement', field: 'order', order: 'desc'},
                    { title: 'A-Z', field: 'name', order: 'asc'},
                    { title: 'Z-A', field: 'name', order: 'desc'},
                    { title: 'Date Created', field: 'id', order: 'desc'},
                    { title: 'Date Modified', field: 'updated_at', order: 'desc'},
                ],
                actionList: false,
                actionList2: false,
                bulkActionDropdown: false,
                bulkActionDropdown2: false,
                editThumbnail: false,
                activeProgressTracking: {},
                isTyping: false,
                isMoreOption: false,
                moreOptionDropdown: 0,
                progressTrackingWizard: false,
                selectedProgress: {},
                selectedProgressTracking: [],
                renameProgressTracking: false,
                previewProgressTracking: false,
                sortProgressTracking: false,
                importProgressTracking: false,
                assignContact: false,
                unassignContact: false,
                progressTrackingIds: [],
                shareProgressTracking: false,
                progressTrackingLink: false,
                manageContact: false,
                perPageFilter: 6,
                search: '',
                page: 1,
                showContent: false,
                searchField: false,
                newProgressTracking: false,
                progressTable: false,
                mobile_filter: false,
            }
        },

        components: {
            EditThumbnailAsset,
            ImportShareLink,
            SortAsset,
            AssignContact,
            UnassignContact,
            ProgressTrackingWizard,
            RenameProgressTracking,
            ShareComponent,
            AssetLink,
            ManageContact,
            PreviewProgressTracking,
            NewProgress,
            ProgressTables
        },

        watch: {
            search (val) {
                const vm = this;

                vm.setDashboardParams({ key: 'search', value: val });

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.setDashboardParams({ key: 'page', value: 1 });
                            vm.getProgressTrackings(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.setDashboardParams({ key: 'page', value: 1 });
                            vm.getProgressTrackings(vm.params);
                        }
                    }
                }
            },

            perPageFilter (perPage) {
                const vm = this;

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'per_page', value: perPage });
                vm.selectedProgressTracking = []

                vm.getProgressTrackings(vm.params);
            },

            editThumbnail (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            importProgressTracking (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            sortProgressTracking (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            assignContact (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            unassignContact (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            renameProgressTracking (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            shareProgressTracking (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            progressTrackingLink (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            manageContact (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            previewProgressTracking (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            progressTrackingWizard (val) {
                if (val) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: {
            ...mapState({
                progressTrackings: state => state.progressTrackingModule.progressTrackings,
                progressTrackingLoader: state => state.progressTrackingModule.progressTrackingLoader,
                params: state => state.progressTrackingModule.params,
            }),

            ...mapGetters({
                companyUserCan: 'authModule/COMPANY_USER_CAN',
            }),
        },

        mounted () {
            const vm = this;

            if (vm.progressTrackings.total == 0 || !vm.progressTrackings.total) {
                vm.getProgressTrackings(vm.params);
            }

            if (vm.params.search) {
                vm.search = vm.params.search;
            }

            if (vm.params.per_page) {
                vm.perPageFilter = vm.params.per_page;
            }

            if (vm.params.filter_title) {
                vm.filterTitle = vm.params.filter_title;
            }

            if (vm.params.page) {
                vm.page = vm.params.page;
            }

            vm.getDefaultCategories();

            document.getElementById('progress_tracking_index').onclick = function() {
                setTimeout(() => {
                    if (!vm.isMoreOption) {
                        vm.moreOptionDropdown = 0;
                    }
                }, 10);
            }
        },

        methods: {
            ...mapActions({
                getProgressTrackings: 'progressTrackingModule/getProgressTrackings',
                duplicateProgressTracking: 'progressTrackingModule/duplicateProgressTracking',
                deleteProgressTracking: 'progressTrackingModule/deleteProgressTracking',
                getDefaultCategories: 'progressTrackingModule/getDefaultCategories',
            }),

            ...mapMutations({
                setSelectedProgressTracking: 'progressTrackingModule/SET_SELECTED_PROGRESS_TRACKING',
                setDashboardParams: 'progressTrackingModule/SET_DASHBOARD_PARAMS',
                resetDashboardParams: 'progressTrackingModule/RESET_DASHBOARD_PARAMS',
            }),

            togglePerPageFilter () {
                const vm = this;
                vm.actionList = false;
                vm.bulkActionDropdown = false;

                const filter = vm.$refs['progress-tracking-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePerPageDropdown () {
                const vm = this;

                const filter = vm.$refs['progress-tracking-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            closeBulkActionDropdown () {
                const vm = this;

                vm.bulkActionDropdown = false;
            },
            closeBulkActionDropdown2 () {
                const vm = this;

                vm.bulkActionDropdown2 = false;
            },

            closeTutorialListDropdown(){
                const vm = this;

                vm.tutorialList = false;
            },

            closeSortingDropdown () {
                const vm = this;

                vm.actionList = false;
            },
            closeSortingDropdown2 () {
                const vm = this;

                vm.actionList2 = false;
            },

            moreOption (id) {
                const vm = this;

                vm.isMoreOption       = true;
                vm.moreOptionDropdown = vm.moreOptionDropdown == id ? 0 : id;

                setTimeout(function () {
                    vm.isMoreOption = false;
                }, 100);
            },

            handlePagination (page) {
                const vm = this;

                vm.page                     = page;
                vm.selectedProgressTracking = []

                vm.setDashboardParams({ key: 'page', value:page });
                vm.getProgressTrackings(vm.params);
            },

            sortingFilter (filter) {
                const vm = this;

                vm.filterTitle              = filter.title;
                vm.selectedProgressTracking = [];

                vm.setDashboardParams({ key: 'filter_title', value: filter.title });
                vm.setDashboardParams({ key: 'order_by', value: filter.field });
                vm.setDashboardParams({ key: 'order', value: filter.order });
                vm.getProgressTrackings(vm.params);
            },

            refreshProgressTracking () {
                const vm = this;

                vm.page                     = 1;
                vm.search                   = '';
                vm.selectedProgressTracking = []

                vm.setDashboardParams({ key: 'page', value: 1 });
                vm.setDashboardParams({ key: 'search', value: '' });
                vm.getProgressTrackings(vm.params);
            },

            handleShareProgressTracking (progressTracking) {
                const vm = this;

                if (progressTracking.share_count == 1) {
                    vm.shareProgressTracking = true;
                    vm.activeProgressTracking = progressTracking;
                } else if (progressTracking.share_count == 0) {
                    const options = Helper.swalWarningOptions('Attention: Sharing Restrictions', `You are currently attempting to share an asset you do not own. Please note that sharing is exclusively for distributing assets you personally own with other Thrive Coach industry peers to help them leverage Thrive Coach with their clients. <br><br> To allocate this asset to a client or contact, kindly use the 'Assign' feature. This ensures proper authorization and tracking of the assignment within the Thrive Coach system.`);

                    Swal.fire(options);
                }
            },

            handleDuplicateProgressTracking (id) {
                const vm = this;

                if (id.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `You want to duplicate ${ id.length > 1 ? 'these client tracking' : 'this client tracking'}`);
                          options.preConfirm = function () {
                                                    return vm.duplicateProgressTracking({ id: id.toString() }).then((result) => {
                                                        vm.search                   = '';
                                                        vm.selectedProgressTracking = [];

                                                        vm.setDashboardParams({ key: 'search', value: '' });
                                                        vm.getProgressTrackings(vm.params);
                                                    });
                                                };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one client tracking to duplicate!');
                }
            },

            handleDeleteProgressTracking (ids) {
                const vm = this;

                if (ids.length) {
                    const options = Helper.swalConfirmOptions('Are you sure?', `This action is permanent. You will not be able to recover ${ ids.length > 1 ? 'these client tracking' : 'this client tracking'}`, 'Delete');
                          options.preConfirm = function () {
                                                    return vm.deleteProgressTracking({ ids }).then((result) => {
                                                        if (result) {
                                                            vm.refreshProgressTracking();
                                                        }
                                                    });
                                                };

                    Swal.fire(options);
                } else {
                    Toastr.error('Please select at least one client tracking to delete!');
                }
            },

            handleAssignContacts (ids) {
                const vm = this;

                if (ids.length) {
                    vm.progressTrackingIds = ids;
                    vm.assignContact    = true;
                } else {
                    Toastr.error('Please select at least one client tracking to assign!');
                }
            },

            handleUnassignContacts (ids) {
                const vm = this;

                if (ids.length) {
                    vm.progressTrackingIds = ids;
                    vm.unassignContact  = true;
                } else {
                    Toastr.error('Please select at least one client tracking to unassign!');
                }
            },

            toggleProgressTrackingWizard (activeProgressTracking) {
                const vm = this;

                vm.setSelectedProgressTracking(activeProgressTracking);
                vm.progressTrackingWizard = true;
            },

            showAction (ev) {
                const vm = this;

                if (ev) {
                    vm.showContent = true;
                } else {
                    vm.showContent = false;
                }
            }
        }
}
</script>

<style scoped>
    .acton_header{
        max-width: 1200px;
        width: 100%;
        margin: 0 auto;
        flex-wrap: wrap;
    }
    .result_wpr.new .actions{
        max-width: 1200px;
        width: 100%;
        margin: 20px auto 10px auto;
    }
    .playbook_list  {
        list-style-type: none;
        margin: 0 -20px;
        display: flex;
        flex-wrap: wrap;
    }

    .playbook_list > li {
        padding: 20px;
        flex: 0 1 33.333%;
        min-width: 255px;
        /* width: 33.333%; */
    }

    .plybook_item {
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        line-height: 0;
        position: relative;
        height: 100%;
    }

    .replace_img {
        position: absolute;
        right: 15px;
        top:15px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 2;
    }

    .replace_img img {
        max-width: 16px;
        height: auto;
    }

    .plybook_item .item_img .checkbox {
        position: absolute;
        left: 15px;
        top: 15px;
    }
    .plybook_item .item_img .checkbox span{
        border-color: #8a8a8a;
    }
    .plybook_item .item_img .checkbox span i {
        color: #2C3E50;
    }

    .plybook_item .item_img {
        height: auto;
        position: relative;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
    }

    .plybook_item .item_img .img {
        height: auto;
        width: 100%;
        object-fit: cover;
    }

    .plybook_item .item_img .statistics {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        /* background: linear-gradient(to right, rgba(243, 243, 243, 1), rgba(255,255,255,0.8) 70%, rgba(255,255,255,0.5)); */
        background: #f5f5f5;
        padding: 30px 15px 15px;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .plybook_item .item_img .statistics li {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #2C3E50;
        padding: 3px 0;
        text-align: left;
    }

    .plybook_item .item_img .statistics li:first-child {
        margin-bottom: auto;
    }

    .plybook_item .img {
        width: 100%;
        height: 280px;
        object-fit: cover;
        border-radius: 12px 12px 0 0;
    }

    .plybook_item .item_ttl {
        font-size: 16px;
        line-height: 22px;
        color: #121525;
        font-weight: 400;
        padding: 15px 20px 0 20px;
        margin-bottom: 15px;
        text-align: left;
        display: block;
        text-decoration: none;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .plybook_item .item_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 20px 20px 20px;
    }

    .plybook_item .item_info .member {
        font-size: 13px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info img {
        margin-right: 5px;
        max-height: 16px;
        width: auto;
    }

    .plybook_item .item_info > ul {
        list-style-type: none;
        display: flex;
        align-items: center;
    }

    .plybook_item .item_info > ul > li {
        width: 30px;
        height: 30px;
        font-size: 15px;
        color: #2f7eed;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #f3f3f3;
        margin-left: 8px;
        cursor: pointer;
        position: relative;
    }

    .plybook_item .item_info .row_option {
        bottom: -10px;
        top: auto;
        animation: dropMove2 .5s ease-in-out;
    }

    .plybook_item .item_info .row_option:before {
        bottom: 18px;
        top: auto;
        left: auto;
        transform: rotate(45deg) scale(1);
    }

    .plybook_item .item_img:hover .statistics, .plybook_item .item_img.active .statistics {
        opacity: 1;
    }

    @keyframes dropMove2  {

        from  {margin-bottom: 10px; opacity: 0;}

        to  {margin-bottom: 1px; opacity: 1;}
    }

    .row_option li a {
        color: inherit;
        text-decoration: none;
    }

    .header_filter{
        position: fixed;
        top: 0;
        bottom: 0;
        background: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
        width: 250px;
        overflow-y: auto;
        right: -250px;
        z-index: 12;
        transition: all 0.3s ease-in-out;
        text-align: left;
    }
    .header_filter.show{
        right: 0;
    }
    .header_filter .close_btn{
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
        height: 20px;
        font-size: 10px;
        border-radius: 50%;
        background: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #5a5a5a;
        cursor: pointer;
    }
    .header_filter .top_area{
        padding: 20px;
    }
    .header_filter .top_area h3{
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-bottom: 15px;
    }
    .header_filter .search_area {
        padding: 0 31px 0 0;
        border-radius: 17px;
        background: #f5f5f5;
        position: relative;
        margin: 15px 0;
    }
    .header_filter .search_area input[type=text] {
        font-size: 11px;
        height: 30px;
        width: 100%;
        background: transparent;
        padding: 0 0 0 15px;
        transition: all 0.3s ease-in-out;
    }
    .header_filter .search_area .search_btn {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        font-size: 11px;
        color: #121525;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .header_filter .top_area ul {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-top: 5px;
    }
    .header_filter .top_area ul li {
        height: 30px;
        width: 30px;
        background: #2f7eed;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.02);
        margin-right: 6px;
    }
    .header_filter .top_area ul li img {
        max-width: 16px;
        height: auto;
        filter: brightness(100);
    }
    .header_filter .action_area{
        padding: 20px;
        border-top: 1px solid #e9e9e9;
    }
    .header_filter .action_area ul li .checkbox h5 {
        font-size: 13px;
        line-height: 16px;
        font-weight: 400;
        color: #121525;
        margin: 5px 0;
    }
    .header_filter .action_area > ul > li {
        font-size: 13px;
        line-height: 18px;
        font-weight: 400;
        color: #5a5a5a;
        text-align: left;
        /* background: #f5f5f5; */
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-bottom: 10px;
    }
    .header_filter .action_area > ul > li.optionDrops{
        padding: 7px 15px;
        border: 1px solid #e9e9e9;
        border-radius: 20px;
    }
    .header_filter .action_area > ul > li > i{
        margin-left: auto;
    }
    .header_filter .action_area .dropdown_wpr ul{
        max-height: 250px;
        overflow-y: auto;
    }
    .header_filter .action_area .dropdown_wpr ul li{
        border: 0;
    }
    .header_filter .action_area .dropdown_wpr ul li.active{
        background: #f5f5f5;
    }
    .result_wpr.new .actions > ul li.filter_btn{
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 3px;
        padding: 4px 6px;
        display: none;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        cursor: pointer;
    }
    .filter_btn span{
        height: 2px;
        width: 100%;
        border-radius: 2px;
        background: #2f7eed;
        margin: 2px 0;
    }
    .filter_btn span:nth-child(2){
        width: 60%;
    }
    /* @media(max-width: 1199px){
        .acton_header{
            flex-wrap: wrap;
        }
        .acton_header .left_section, .acton_header .right_section{
            width: 100%;
        }
        .acton_header .right_section{
            margin-top: 25px;
        }
        .acton_header .left_section .search_area, .acton_header .right_section > ul > li.add_btn{
            margin-left: auto;
        }
        .acton_header .search_area input, .acton_header .search_area .search_btn{
            height: 30px;
        }
    } */
    @media(max-width: 1199px){
        .acton_header .search_area input{
            width: 200px;
            height: 30px;
        }
        .acton_header .search_area .search_btn{
            height: 30px;
        }
        .acton_header .left_section .search_area{
            display: none;
        }
        .acton_header .right_section > ul > li:not(.add_btn){
            display: none;
        }
        .result_wpr.new .actions > ul > li:not(.tutorial_btn, .sort_list, .filter_btn){
            display: none;
        }
        .result_wpr.new .actions > ul li.filter_btn{
            display: flex;
        }
    }
    /* @media(max-width: 600px){
        .acton_header {
            gap: 0;
        }
        .acton_header .search_area input{
            width: 170px;
        }
        .acton_header .right_section, .result_wpr.new .actions{
            margin-top: 15px;
        }
        .result_wpr.new .actions > ul li button.video_btn{
            margin-left: 10px;
        }
        .result_wpr.new .actions > ul.left, .result_wpr.new .actions > ul.right{
            width: calc(100% + 14px);
        }
        .result_wpr.new .actions > ul.left li.tutorial_btn, .result_wpr.new .actions > ul.right li.sort_list{
            margin-left: auto;
        }
        .tutorial_btn .dropdown_wpr{
            right: 1px;
            left: auto;
        }
    } */
    @media(min-width: 1200px){
        .header_filter{
            display: none;
        }
    }
</style>
